import React, { Component, Fragment } from "react";
import requestBff from "../utils/RequestBff";
import { PropTypes } from "prop-types";
import "../App.css";
import { createBrowserHistory } from "history";
export const history = createBrowserHistory();

export default class Logout extends Component {
  static contextTypes = {
    route: PropTypes.object,
  };

  async componentDidMount() {
    var urlLogout = this.props.config.bff + "/user/logout";
    requestBff(urlLogout, "post")
      .then((response) => {
        sessionStorage.clear();
        this.props.history.push("/");
      })
      .catch((error) => {
        console.error(JSON.stringify(error));
      });
  }

  componentDidCatch(error, info) {
    console.error(error);
    console.error(info);
  }

  render() {
    return <Fragment>Efetuando log out</Fragment>;
  }
}
