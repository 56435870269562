import { Stack } from "@mui/material/";
import Avatar from "@mui/material/Avatar";
import style from "../css/head.module.css";
import { Link } from "@mui/material";
import userInfo from "../utils/userInfo";

import { AiFillCaretRight } from "react-icons/ai";
const Menu = () => {
  const user = userInfo();
  const logout = () => {
    sessionStorage.removeItem("user-info");
  };
  return (
    <header className={style.header}>
      <Stack width={"100%"}>
        <img
          className={style.image}
          src="/assets/LogoARLepton_Horizontal.png"
          alt=""
        />
      </Stack>
      <Stack sx={{ pr: 4 }}>
        <Avatar
          sx={{ bgcolor: "orange", }}
          alt={user?.userInfo?.name}
          src="/broken-image.jpg"
        />
        <div className={style.contentInfoProfile}>
          <span className={style.name}>
            {user?.userInfo?.name}
            <AiFillCaretRight className={style.icon}></AiFillCaretRight>
          </span>
          <span className={style.cargo}>Dev Op Sênior</span>
          <Link
            onClick={logout}
            style={{ textDecoration: "none" }}
            href="/logout"
          >
            <span className={style.logOut}>Log out</span>
          </Link>
        </div>
      </Stack>
    </header>
  );
};

export default Menu;
