import { Link, Box } from "@mui/material";
import { React, Component, Fragment } from "react";
import { PropTypes } from "prop-types";
import { createBrowserHistory } from "history";
export const history = createBrowserHistory();

export default class PageHead extends Component {
  static contextTypes = {
    route: PropTypes.object,
  };

  constructor(props) {
    super(props);
    var authenticated = this.props.authCode != null;
    if (authenticated) {
      this.state = {
        authenticated: this.props.authenticated,
        authCode: this.props.authCode,
        username: this.props.userInfo.username,
        email: this.props.userInfo.email,
        name: this.props.userInfo.name,
      };
    } else {
      this.state = {
        authenticated: false,
        authCode: this.props.authCode,
        username: null,
        email: null,
        name: null,
      };
    }
  }

  componentDidMount() {}

  render() {
    const username = this.state.authenticated ? (
      <Box sx={{ float: "right", margin: "12px 12px 0px 0px" }}>
        <Link href="#">{this.props.userInfo.name} </Link>
        <Link onClick={this.doLogout} href="/logout">
          (Sair)
        </Link>
      </Box>
    ) : (
      <Fragment></Fragment>
    );

    return (
      <header>
        <Link sx={{ display: "inline-block" }} underline="none" href="/">
          <img
            src="/assets/arlepton.png"
            width="70"
            height="70"
            alt="ARLepton Router Admin"
          />
        </Link>
        <Box
          sx={{
            margin: "0px 0px 0px 8px",
            verticalAlign: "top",
            width: "80%",
            display: "inline-block",
            fontWeight: "bold",
            fontSize: "30px",
            whiteSpace: "pre",
          }}
        >
          <Box sx={{ color: "#00D2F0", display: "left" }}>ARLepton</Box>
          <Box sx={{ color: "#fff", display: "left" }}>Generator</Box>
        </Box>
        {username}
      </header>
    );
  }
}
