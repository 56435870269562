import axios from "axios";

export default function requestBff(url, method, body) {
  var headersAndCredentials = {
    withCredentials: true,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  };
  if (method.toLowerCase() === "post") {
    return axios.post(url, body, headersAndCredentials);
  } else if (method.toLowerCase() === "delete") {
    return axios.delete(url);
  } else {
    return axios.get(url, headersAndCredentials);
  }
}
