import axios from "axios";

export default function requestTemplate(url, method, token, body) {
  var bearerToken = "Bearer " + token;
  var headers = {
    "Content-Type": "application/json",
    //'Authorization': bearerToken
  };
  if (method.toLowerCase() === "put") {
    return axios.put(url, body, { headers: headers });
  } else if (method.toLowerCase() === "post") {
    return axios.post(url, body, { headers: headers });
  } else if (method.toLowerCase() === "delete") {
    return axios.delete(url, { headers: headers });
  } else {
    return axios.get(url, { headers: headers });
  }
}
