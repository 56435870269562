import Cookies from "js-cookie";
import { Base64 } from "js-base64";

export default function userInfo() {
  var authCode =
    Cookies.get("auth-code") != null ? Cookies.get("auth-code") : null;
  var encUserInfo = sessionStorage.getItem("user-info");
  var userInfo = encUserInfo != null ? Base64.decode(encUserInfo) : null;
  var authenticated = authCode ? true : false;

  return {
    authenticated: authenticated,
    authCode: authCode,
    userInfo: JSON.parse(userInfo),
  };
}
