import React, { Fragment, useState, useEffect } from "react";
import { Route, Router } from "react-router-dom";
import axios from "axios";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Welcome from "./pages/Welcome";
import Setup from "./pages/Setup";
import history from "./utils/history";
import Download from "./pages/Download";

import "./App.css";

export default function App() {
  const [loading, setLoading] = useState(true);
  const [config, setConfig] = useState({});

  useEffect(() => {
    axios
      .get("/config/config.json")
      .then((response) => {
        setConfig(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error when retrieving endpoint config data");
      });
  }, []);

  if (loading) {
    return <div>Loading configurations</div>;
  } else {
    return (
      <Fragment>
        <Router history={history} config={config}>
          <Route
            path="/"
            exact
            render={(props) => <Login history={history} config={config} />}
          />

          <Route
            path="/welcome"
            exact
            render={(props) => <Welcome history={history} config={config} />}
          />
          <Route
            path="/setup"
            exact
            render={(props) => <Setup history={history} config={config} />}
          />
          <Route
            path="/download/:architectureModel"
            exact
            render={(props) => <Download history={history} config={config} />}
          />
          <Route
            path="/logout"
            exact
            render={(props) => <Logout history={history} config={config} />}
          />
        </Router>
      </Fragment>
    );
  }
}
