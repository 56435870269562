import { Component } from "react";
import { Link } from '@mui/material';

export default class PageFooter extends Component {

    componentDidMount() {

    }

    render() {
        return (
            <footer>
                <b>ARLepton Router Admin</b> - &copy;<Link sx={{display: 'inline-block'}} underline="none" href="https://arphoenix.com.br">ARPhoenix</Link>
            </footer>);
    }

}
