import { Button, IconButton, Stack } from "@mui/material/";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import {
  AiFillAlert,
  AiFillCaretRight,
  AiFillInfoCircle,
  AiOutlineCaretDown,
} from "react-icons/ai";
import DeleteIcon from "@mui/icons-material/Delete";
import Menu from "../components/Menu";
import PageFooter from "../components/PageFooter";
import requestBff from "../utils/RequestBff";
import requestTemplate from "../utils/RequestTemplate";
import userInfo from "../utils/userInfo";

import { Add } from "@mui/icons-material";
import { MdOutlineTimelapse } from "react-icons/md";
import style from "../css/setup.module.css";

const Setup2 = (props) => {
  const [fluxo, setFluxo] = useState(false);
  const [runtime, setRuntime] = useState(false);
  const [multiTenancy, setMultiTenancy] = useState(false);
  const [smtp, setSmtp] = useState(false);
  const [info, setInfo] = useState(true);
  const [cors, setCors] = useState(false);
  const [architectureModel, setArchitectureModel] = useState(false);
  const [keycloak, setKeyclock] = useState(false);
  const [docker, setDocker] = useState(false);
  const [kubernetes, setKubernetes] = useState(false);
  const [disabledDocker, setDisabledDocker] = useState(false);
  const [disabledKeycloak, setDisabledKeycloak] = useState(false);
  const [disabledKubernetes, setDisabledKubernetes] = useState(false);
  const [token, setToken] = useState();
  const [working, setWorking] = useState();
  const [error, setRequestError] = useState();
  const config = props.config;
  const infoUser = userInfo();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
    setFocus,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      groupID: "br.com.demoproject.api",
      artifactID: "",
      version: "0.1.0",
      database: "none",
      port: "8080",
      container: "undertow",
      cache: "none",
      nosql: "none",
      mensageria: "none",
      openApi: false,
      keycloak: false,
      realm: "Realm",
      url: "https://auth-demoproject.arlepton.com/",
      clientID: "demoproject",
      clientSecret: "",
      docker: "demoproject.azurecr.io",
      dockerNamespace: "",
      secretpullname: "registry-pull",
      kubernetes: false,
    },
  });
  const onSubmit = async (data) => {
    if (
      data.architectureModel === "Selecione" ||
      data.architectureModel === undefined
    ) {
      setArchitectureModel(true);
      setError("architectureModel", { message: "Campo obrigatório!" });
      return setFocus("architectureModel");
    }
    if (data.database === "none" && multiTenancy) {
      setError("database", { message: "Campo obrigatório!" });
      return setFocus("database");
    }
    await createMicroService(data);
  };

  useEffect(() => {
    if (infoUser.authenticated === false) {
      props.history.push("/");
    }
  }, [infoUser.authenticated]);

  const createMicroService = async (data) => {
    const urlToken = config.bff + "/user/token";
    const generatorEndpoint = config.generator + "/java/generate";
    const newArray = cors
      ? numberElementsCors.map((item) => {
          return data.cors[item];
        })
      : "";

    let payload = {
      groupId: data.groupID,
      artifactId: String(data.artifactID).toLowerCase(),
      version: data.version,
      port: data.port,
      container: data.container,
      database: data.database,
      cache: data.cache,
      nosql: data.nosql,
      amqp: data.mensageria,
      openapi: data.openApi,
      devtools: true,
      actuator: true,

      features: {
        emailAdmin: data.emailAdmin,
        multitenancy: {
          enabled: multiTenancy,
          domain: data.tenantDomain,
          type: data.multitenancyType
        },
        cors: {
          enable: cors,
          allowedOrigins: newArray.toString(),
        },
        email: {
          enable: smtp,
          host: data.emailHost ?? "",
          port: data.emailPort ?? "",
          username: data.emailUsername ?? "",
          password: data.emailPassword ?? "",
          recoveryPasswordDomain: data.emailRecoveryPasswordDomain ?? "",
          recoveryPasswordTokenTtlMinutes:
            data.emailRecoveryPasswordTokenMinutes ?? "",
          debug: data.emailDebug ?? false,
        },
        security: {
          mode: data.architectureModel,
          internal: {
            timeZoneId: data.timeZoneId ?? "",
            expirationInSecondsToken: data.expirationInSecondsToken ?? 0,
            expirationInSecondsRefreshToken:
              data.expirationInSecondsRefreshToken ?? 0,
          },
          oauth2: {
            issuer: data.issuer ?? "",
            algorithm: data.algorithm ?? "",
            publicKey: data.PublicKey ?? "",
          },
        },
      },
      keycloak: {
        useKeycloak: disabledKeycloak,
        realm: data.realm,
        url: data.url,
        clientid: data.clientID,
        clientsecret: data.clientSecret,
      },
      docker: {
        containerRegistry: data.docker,
        dockerNamespace: data.dockernamespace,
      },
      k8s: {
        generatek8s: disabledKubernetes,
        useSecretToPullImage: data.kubernetes,
        secretPullName: data.secretpullName,
      },
    };

    await requestBff(urlToken, "get", null)
      .then((response) => {
        setToken({ token: response.data });
        console.log("Enviando dados para geração do MS");
        setWorking({ working: true });
        requestTemplate(generatorEndpoint, "post", response.data, payload)
          .then((response1) => {
            sessionStorage.setItem("ms", JSON.stringify(response1.data));
            props.history.push(`/download/${data.architectureModel}`);
          })
          .catch((error) => {
            setError(error.response?.data[0]?.message);
            setWorking(false);
          });
      })
      .catch((error) => {
        console.error(JSON.stringify(error.message));
      });
  };

  const [numberElementsCors, setNumberElementsCors] = useState([1]);

  useEffect(() => {
    if (watch("architectureModel") === "internal") {
      setValue("timeZoneId", "America/Sao_Paulo");
      setValue("expirationInSecondsToken", 300);
      setValue("expirationInSecondsRefreshToken", 720);
      setValue("issuer", "");
      setValue("algorithm", "");
      setCors(true);
      setSmtp(true);
    } else {
      setValue("timeZoneId", "");
      setValue("expirationInSecondsToken", "");
      setValue("expirationInSecondsRefreshToken", "");
      setValue("issuer", "");
      setValue("algorithm", "");
    }
  }, [watch("architectureModel")]);

  useEffect(() => {
    if (cors) {
      setValue("cors[1]", "http://localhost:5000");
    } else {
      setValue("cors[1]", "");
    }
  }, [cors]);

  useEffect(() => {
    if (smtp) {
      setValue("emailPort", "587");
      setValue("emailRecoveryPasswordTokenMinutes", "1");
    }
  }, [smtp]);

  return (
    <div className={style.bg}>
      <Menu />
      <div className={style.container}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={style.content}>
            <div className={style.contentIcon}>
              <AiFillInfoCircle className={style.icon}></AiFillInfoCircle>
            </div>
            <div className={style.contentInfo}>
              <div onClick={() => setInfo(!info)} className={style.title}>
                Informações Básicas
                {info ? (
                  <AiOutlineCaretDown
                    onClick={() => setInfo(!info)}
                  ></AiOutlineCaretDown>
                ) : (
                  <AiFillCaretRight
                    onClick={() => setInfo(!info)}
                  ></AiFillCaretRight>
                )}
              </div>
              {info && (
                <Stack
                  direction={{ xs: "column", lg: "row" }}
                  alignItems="end"
                  flexWrap={{ xs: "wrap", md: "nowrap" }}
                  mb={2}
                  className={style.contentForm}
                >
                  <Stack width="100%" gap={0.5}>
                    <label className={style.label} htmlFor="GroupID">
                      Group ID: *
                      {errors.groupID ? (
                        <span className={style.error}>Campo obrigatório!</span>
                      ) : (
                        ""
                      )}
                    </label>
                    <input
                      className={style.inputSingle}
                      type="text"
                      id="GroupID"
                      placeholder="Group ID"
                      {...register("groupID", { required: true })}
                    />
                  </Stack>
                  <Stack width="100%" gap={0.5}>
                    <label className={style.label} htmlFor="ArtifactID">
                      Artifact ID: *
                      {errors.artifactID ? (
                        <span className={style.error}>Campo obrigatório!</span>
                      ) : (
                        ""
                      )}
                    </label>

                    <input
                      className={style.inputSingle}
                      type="text"
                      id="ArtifactID"
                      placeholder="Artifact ID"
                      {...register("artifactID", { required: true })}
                    />
                  </Stack>
                  <Stack width="100%" gap={0.5}>
                    <label className={style.label} htmlFor="Version">
                      Version: *
                      {errors.version ? (
                        <span className={style.error}>Campo obrigatório!</span>
                      ) : (
                        ""
                      )}
                    </label>
                    <input
                      className={style.inputSingle}
                      type="text"
                      id="Version"
                      placeholder="Version"
                      {...register("version", { required: true })}
                    />
                  </Stack>
                </Stack>
              )}
            </div>
          </div>
          <div className={style.content}>
            <div className={style.contentIcon}>
              <AiFillInfoCircle className={style.icon}></AiFillInfoCircle>
            </div>
            <div className={style.contentInfo}>
              <div
                onClick={() => setArchitectureModel(!architectureModel)}
                className={style.title}
              >
                Tipos de Projetos
                {architectureModel ? (
                  <AiOutlineCaretDown
                    onClick={() => setArchitectureModel(!architectureModel)}
                  ></AiOutlineCaretDown>
                ) : (
                  <AiFillCaretRight
                    onClick={() => setArchitectureModel(!architectureModel)}
                  ></AiFillCaretRight>
                )}
              </div>
              {architectureModel && (
                <Stack
                  direction={{ xs: "column", md: "row" }}
                  flexWrap={{ xs: "wrap" }}
                  gap={2}
                  mb={2}
                  className={style.contentForm}
                  display={{ md: "grid" }}
                  gridTemplateColumns={{ md: "1fr 1fr" }}
                >
                  <Stack>
                    <label className={style.label} htmlFor="architectureModel">
                      Modelo: *
                      {errors.architectureModel ? (
                        <span className={style.error}>Campo obrigatório!</span>
                      ) : (
                        ""
                      )}
                    </label>
                    <select
                      className={style.inputSingle}
                      type="text"
                      id="architectureModel"
                      placeholder="architectureModel"
                      {...register("architectureModel", { required: true })}
                    >
                      <option value={null} selected={true} disabled>
                        Selecione
                      </option>
                      <option value={"internal"}>Projeto fullStack</option>
                      <option value={"none"}>Projeto backend padrão</option>
                      <option value={"oauth2"}>Projeto backend oAuth2</option>
                    </select>
                  </Stack>

                  {watch("architectureModel") === "internal" &&
                    architectureModel && (
                      <Stack>
                        <label className={style.label} htmlFor="emailAdmin">
                          E-mail Administrador: *
                          {errors.emailAdmin ? (
                            <span className={style.error}>
                              Campo obrigatório!
                            </span>
                          ) : (
                            ""
                          )}
                        </label>

                        <input
                          className={style.inputSingle}
                          type="email"
                          id="emailAdmin"
                          placeholder="exemplo@exemplo.com"
                          {...register("emailAdmin", {
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: "E-mail inválido",
                            },
                            required: true,
                          })}
                        />
                      </Stack>
                    )}
                </Stack>
              )}
              {watch("architectureModel") === "internal" &&
                architectureModel && (
                  <Stack
                    flexDirection={{ xs: "column", md: "row" }}
                    flexWrap={{ xs: "wrap", lg: "nowrap" }}
                    gap={1}
                    display={"flex"}
                    alignItems={"center"}
                    mb={"25px"}
                  >
                    <Stack width="100%" gap={0.5}>
                      <label className={style.label} htmlFor="timeZoneId">
                        Timezone: *
                        {errors.timeZoneId && (
                          <span className={style.error}>
                            Campo obrigatório!
                          </span>
                        )}
                      </label>
                      <input
                        className={style.inputSingle}
                        type="text"
                        id="timeZoneId"
                        placeholder="Ex: America/Sao_Paulo"
                        {...register("timeZoneId", {
                          required: true,
                        })}
                      />
                    </Stack>
                    <Stack width="100%" gap={0.5}>
                      <label
                        className={style.label}
                        htmlFor="expirationInSecondsToken"
                      >
                        Expiração do Token (segundos): *
                        {errors.expirationInSecondsToken && (
                          <span className={style.error}>
                            Campo obrigatório!
                          </span>
                        )}
                      </label>
                      <input
                        className={style.inputSingle}
                        type="number"
                        id="expirationInSecondsToken"
                        placeholder="300"
                        min={0}
                        {...register("expirationInSecondsToken", {
                          valueAsNumber: true,
                          pattern: /^[0-9]*$/,
                          required: true,
                        })}
                      />
                    </Stack>
                    <Stack width="100%" gap={0.5}>
                      <label
                        className={style.label}
                        htmlFor="expirationInSecondsRefreshToken"
                      >
                        Expiração do Refresh Token (segundos): *
                        {errors.expirationInSecondsRefreshToken && (
                          <span className={style.error}>
                            Campo obrigatório!
                          </span>
                        )}
                      </label>
                      <input
                        className={style.inputSingle}
                        type="number"
                        id="expirationInSecondsRefreshToken"
                        placeholder="720"
                        min={0}
                        {...register("expirationInSecondsRefreshToken", {
                          valueAsNumber: true,
                          pattern: /^[0-9]*$/,
                          required: true,
                        })}
                      />
                    </Stack>
                  </Stack>
                )}
              {watch("architectureModel") === "oauth2" && architectureModel && (
                <Stack
                  flexDirection={{ xs: "column", md: "row" }}
                  flexWrap={{ xs: "wrap", lg: "nowrap" }}
                  gap={1}
                  display={"flex"}
                  mb={"25px"}
                  alignItems={"initial"}
                >
                  <Stack width="100%" gap={0.5}>
                    <label className={style.label} htmlFor="issuer">
                      Issuer: *
                      {errors.issuer && (
                        <span className={style.error}>Campo obrigatório!</span>
                      )}
                    </label>
                    <input
                      className={style.inputSingle}
                      type="text"
                      id="issuer"
                      placeholder="Issuer"
                      {...register("issuer", {
                        required: true,
                      })}
                    />
                  </Stack>
                  <Stack width="100%" gap={0.5}>
                    <label className={style.label} htmlFor="algorithm">
                      Algorithm: *
                      {errors.algorithm && (
                        <span className={style.error}>Campo obrigatório!</span>
                      )}
                    </label>
                    <input
                      className={style.inputSingle}
                      id="algorithm"
                      placeholder="Algorithm"
                      min={0}
                      {...register("algorithm", {
                        required: true,
                      })}
                    />
                  </Stack>
                  <Stack width="100%" gap={0.5}>
                    <label className={style.label} htmlFor="PublicKey">
                      PublicKey: *
                      {errors.PublicKey && (
                        <span className={style.error}>Campo obrigatório!</span>
                      )}
                    </label>
                    <textarea
                      rows={5}
                      className={style.textArea}
                      style={{ resize: "none" }}
                      id="PublicKey"
                      placeholder="PublicKey"
                      min={0}
                      {...register("PublicKey", {
                        required: true,
                      })}
                    />
                  </Stack>
                </Stack>
              )}
            </div>
          </div>
          <div className={style.content}>
            <div className={style.contentIcon}>
              <MdOutlineTimelapse className={style.icon}></MdOutlineTimelapse>
            </div>
            <div className={style.contentInfo}>
              <div onClick={() => setRuntime(!runtime)} className={style.title}>
                Runtime
                {runtime ? (
                  <AiOutlineCaretDown
                    onClick={() => setRuntime(!runtime)}
                  ></AiOutlineCaretDown>
                ) : (
                  <AiFillCaretRight
                    onClick={() => setRuntime(!runtime)}
                  ></AiFillCaretRight>
                )}
              </div>
              {runtime && (
                <Stack
                  direction={{ xs: "column", md: "row" }}
                  gap={2}
                  flexWrap={{ xs: "wrap", md: "nowrap" }}
                  width={"93%"}
                  mb={2}
                  className={style.contentForm}
                >
                  <Stack width="100%" gap={0.5}>
                    <label className={style.label} htmlFor="container">
                      Container
                    </label>
                    <select
                      className={style.inputSingle}
                      type="text"
                      id="container"
                      placeholder="container"
                      {...register("container")}
                    >
                      <option value={"undertow"} selected={true}>
                        Undertow
                      </option>
                      <option value={"tomcat"}>Tomcat</option>
                      <option value={"jetty"}>Jetty</option>
                    </select>
                  </Stack>
                  <Stack width="100%" gap={0.5}>
                    <label className={style.label} htmlFor="port">
                      Port
                    </label>
                    <input
                      className={style.inputSingle}
                      type="text"
                      id="port"
                      placeholder="Port"
                      {...register("port")}
                    />
                  </Stack>
                </Stack>
              )}
            </div>
          </div>
          <div className={style.content}>
            <div className={style.contentIcon}>
              <img width={"30px"} src="/assets/IconFluxoDados.svg" alt="" />
            </div>
            <div className={style.contentInfo}>
              <div onClick={() => setFluxo(!fluxo)} className={style.title}>
                Fluxo de Dados
                {fluxo ? (
                  <AiOutlineCaretDown
                    onClick={() => setFluxo(!fluxo)}
                  ></AiOutlineCaretDown>
                ) : (
                  <AiFillCaretRight
                    onClick={() => setFluxo(!fluxo)}
                  ></AiFillCaretRight>
                )}
              </div>
              {fluxo ? (
                <Stack
                  direction={{ xs: "column", md: "row" }}
                  flexWrap={{ xs: "wrap" }}
                  gap={2}
                  mb={2}
                  className={style.contentForm}
                  display={{ md: "grid" }}
                  gridTemplateColumns={{ md: "1fr 1fr" }}
                >
                  <Stack>
                    <label className={style.label} htmlFor="database">
                      Database: *
                      {errors.database ? (
                        <span className={style.error}>Campo obrigatório!</span>
                      ) : (
                        ""
                      )}
                    </label>
                    <select
                      className={style.inputSingle}
                      type="text"
                      id="database"
                      placeholder="Database"
                      {...register("database", { required: multiTenancy })}
                      onChange={(e) => {
                        clearErrors("database");
                      }}
                    >
                      <option value={"none"} selected={true}>
                        Nenhum
                      </option>
                      <option value={"oracle"}>Oracle</option>
                      <option value={"postgresql"}>PostgreSQL</option>
                      <option value={"mysql"}>MySQL</option>
                    </select>
                  </Stack>
                  <Stack>
                    <label className={style.label} htmlFor="cache">
                      Cache
                    </label>
                    <select
                      className={style.inputSingle}
                      type="text"
                      id="cache"
                      placeholder="Cache"
                      {...register("cache")}
                    >
                      <option value={"none"}>Nenhum</option>
                      <option value={"redis"}>Redis</option>
                      <option value={"caffeine"}>Caffeine</option>
                    </select>
                  </Stack>
                  <Stack>
                    <label className={style.label} htmlFor="nosql">
                      NoSQL
                    </label>
                    <select
                      className={style.inputSingle}
                      type="text"
                      id="nosql"
                      placeholder="NoSQL"
                      {...register("nosql")}
                    >
                      <option value={"none"}>Nenhum</option>
                      <option value={"mongo"}>MongoDB</option>
                    </select>
                  </Stack>
                  <Stack>
                    <label className={style.label} htmlFor="mensageria">
                      Mensageria
                    </label>
                    <select
                      className={style.inputSingle}
                      type="text"
                      id="mensageria"
                      placeholder="Mensageria"
                      {...register("mensageria")}
                    >
                      <option value={"none"}>Nenhum</option>
                      <option value={"kafka"}>Kafka</option>
                      <option value={"rabbit"}>RabbitMQ</option>
                    </select>
                  </Stack>
                  <Stack>
                    <label className={style.label} htmlFor="openApi">
                      OpenApi
                    </label>
                    <input
                      className={style.check}
                      {...register("openApi")}
                      type="checkbox"
                    />
                  </Stack>
                  {watch("architectureModel") === "internal" && (
                    <Stack>
                      <label className={style.label} htmlFor="openApi">
                        Multitenancy
                      </label>
                      <input
                        className={style.check}
                        type="checkbox"
                        onChange={(e) => {
                          setMultiTenancy(e.target.checked);
                          const db = watch("database");
                          if (db === "none") {
                            setError("database", {
                              message: "Campo obrigatório!",
                              shouldFocus: true,
                            });
                          }
                        }}
                        name=""
                        id="multiTenancy"
                        checked={multiTenancy}
                      />
                    </Stack>
                  )}
                  {multiTenancy && (
                    <>
                      <Stack width="100%" gap={0.5}>
                        <label className={style.label} htmlFor="tenantDomain">
                          Tenant: *
                          {errors.tenantDomain && (
                            <span className={style.error}>
                              Campo obrigatório!
                            </span>
                          )}
                        </label>
                        <input
                          className={style.inputSingle}
                          type="text"
                          id="tenantDomain"
                          placeholder="Ex: portal.arphoenix.com.br"
                          {...register("tenantDomain", {
                            required: multiTenancy,
                          })}
                        />
                      </Stack>
                      <Stack>
                        <label className={style.label} htmlFor="multitenancyType">
                          Tipo: *
                        </label>
                        <select
                          className={style.inputSingle}
                          type="text"
                          id="multitenancyType"
                          placeholder="multitenancyType"
                          defaultValue={"schema"}
                          {...register("multitenancyType")}
                        >
                          <option value={"schema"}>Multi Schema</option>
                          <option value={"databaseModel"}>Multi Database</option>
                        </select>
                      </Stack>
                    </>
                  )}
                </Stack>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className={style.content}>
            <div className={style.contentIcon}>
              <AiFillInfoCircle className={style.icon}></AiFillInfoCircle>
            </div>
            <div className={style.contentInfo}>
              <div
                onClick={() => {
                  if (watch("architectureModel") === "internal") return;
                  setCors(!cors);
                }}
                className={style.title}
              >
                <Stack flexDirection="row" gap={0.5}>
                  Cors
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      if (watch("architectureModel") === "internal") return;
                      setCors(e.target.checked);
                    }}
                    name=""
                    id="Cors"
                    checked={cors}
                  />
                </Stack>

                {cors ? (
                  <AiOutlineCaretDown
                    onClick={() => {
                      if (watch("architectureModel") === "internal") return;
                      setCors(!cors);
                    }}
                  ></AiOutlineCaretDown>
                ) : (
                  <AiFillCaretRight
                    onClick={() => {
                      if (watch("architectureModel") === "internal") return;
                      setCors(!cors);
                    }}
                  ></AiFillCaretRight>
                )}
              </div>
              {cors && (
                <Stack
                  direction={{ xs: "column", md: "row" }}
                  alignItems="end"
                  mb={2}
                  className={style.contentForm}
                >
                  <Stack width="100%" gap={0.5}>
                    <label
                      className={style.label}
                      style={{ width: "280px" }}
                      htmlFor="cors"
                    >
                      Origens Permitidas
                      <span className={style.error}>
                        {errors.cors ? "Campo(s) obrigatório(s)" : " "}
                      </span>
                    </label>

                    <Stack
                      display="flex"
                      flexDirection={"row"}
                      columnGap={4}
                      rowGap={1}
                      flexWrap={"wrap"}
                    >
                      {numberElementsCors.map((item) => (
                        <Stack position="relative" key={item}>
                          <input
                            className={style.inputSingle}
                            type="text"
                            id="cors"
                            placeholder="Origens Permitidas"
                            {...register(`cors[${item}]`, { required: cors })}
                          />
                          <IconButton
                            aria-label="delete"
                            color="primary"
                            position="absolute"
                            sx={{ position: "absolute", right: -58, top: -10 }}
                            onClick={() => {
                              setNumberElementsCors(
                                numberElementsCors.filter(
                                  (number) => number !== item
                                )
                              );
                              setValue(`cors[${item}]`, "");
                            }}
                          >
                            {item !== 1 && (
                              <DeleteIcon
                                sx={{ "&:hover": { color: "red" } }}
                                viewBox="0 0 32 32"
                              />
                            )}
                          </IconButton>
                        </Stack>
                      ))}
                      <Button
                        type="button"
                        variant="contained"
                        sx={{ marginLeft: "60px" }}
                        onClick={() =>
                          setNumberElementsCors([
                            ...numberElementsCors,
                            numberElementsCors.length + 1,
                          ])
                        }
                      >
                        <Add />
                      </Button>
                    </Stack>
                  </Stack>
                </Stack>
              )}
            </div>
          </div>
          <div className={style.content}>
            <div className={style.contentIcon}>
              <MdOutlineTimelapse className={style.icon}></MdOutlineTimelapse>
            </div>
            <div className={style.contentInfo}>
              <div
                onClick={() => {
                  if (watch("architectureModel") === "internal") return;
                  setSmtp(!smtp);
                }}
                className={style.title}
              >
                <Stack
                  flexDirection="row"
                  gap={0.5}
                  display={"flex"}
                  alignItems={"center"}
                >
                  Configuração SMTP
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      if (watch("architectureModel") === "internal") return;
                      setSmtp(e.target.checked);
                    }}
                    checked={smtp}
                    id="smtp"
                    style={{ width: "13px", height: "13px" }}
                  />
                </Stack>
                {smtp ? (
                  <AiOutlineCaretDown
                    onClick={() => {
                      if (watch("architectureModel") === "internal") return;
                      setSmtp(!smtp);
                    }}
                  ></AiOutlineCaretDown>
                ) : (
                  <AiFillCaretRight
                    onClick={() => {
                      if (watch("architectureModel") === "internal") return;
                      setSmtp(!smtp);
                    }}
                  ></AiFillCaretRight>
                )}
              </div>
              {smtp && (
                <Stack
                  direction={{ xs: "column", md: "row" }}
                  flexWrap={{ xs: "wrap" }}
                  gap={2}
                  padding={2}
                  mb={2}
                  className={style.contentForm}
                  display={{ md: "grid" }}
                  gridTemplateColumns={{ md: "1fr 1fr" }}
                >
                  <Stack width="100%" gap={0.5}>
                    <label className={style.label} htmlFor="emailHost">
                      Host: *
                      {errors.emailHost && (
                        <span className={style.error}>Campo obrigatório!</span>
                      )}
                    </label>
                    <input
                      className={style.inputSingle}
                      type="text"
                      id="emailHost"
                      placeholder="Ex: smtp.gmail.com"
                      {...register("emailHost", {
                        required: smtp,
                      })}
                    />
                  </Stack>
                  <Stack width="100%" gap={0.5}>
                    <label className={style.label} htmlFor="emailPort">
                      Porta do SMTP: *
                      {errors.emailPort && (
                        <span className={style.error}>Campo obrigatório!</span>
                      )}
                    </label>
                    <input
                      className={style.inputSingle}
                      type="text"
                      id="emailPort"
                      placeholder="Ex: 587"
                      {...register("emailPort", { required: smtp })}
                    />
                  </Stack>
                  <Stack width="100%" gap={0.5}>
                    <label className={style.label} htmlFor="emailUsername">
                      Username: *
                      {errors.emailUsername && (
                        <span className={style.error}>Campo obrigatório!</span>
                      )}
                    </label>
                    <input
                      className={style.inputSingle}
                      type="text"
                      id="emailUsername"
                      placeholder="Ex: smtp@gmail.com"
                      {...register("emailUsername", { required: smtp })}
                    />
                  </Stack>
                  <Stack width="100%" gap={0.5}>
                    <label className={style.label} htmlFor="emailPassword">
                      Password: *
                      {errors.emailPassword && (
                        <span className={style.error}>Campo obrigatório!</span>
                      )}
                    </label>
                    <input
                      className={style.inputSingle}
                      type="text"
                      id="emailPassword"
                      placeholder="********"
                      {...register("emailPassword", { required: smtp })}
                    />
                  </Stack>
                  <Stack width="100%" gap={0.5}>
                    <label
                      className={style.label}
                      htmlFor="emailRecoveryPasswordDomain"
                    >
                      Domínio de senha de recuperação: *
                      {errors.emailRecoveryPasswordDomain && (
                        <span className={style.error}>Campo obrigatório!</span>
                      )}
                    </label>
                    <input
                      className={style.inputSingle}
                      type="text"
                      id="emailRecoveryPasswordDomain"
                      placeholder="Ex: http://localhost:8080"
                      {...register("emailRecoveryPasswordDomain", {
                        required: smtp,
                      })}
                    />
                  </Stack>
                  <Stack width="100%" gap={0.5}>
                    <label
                      className={style.label}
                      htmlFor="emailRecoveryPasswordTokenMinutes"
                    >
                      Token recuperação de senha (minutos): *
                      {errors.emailRecoveryPasswordTokenMinutes && (
                        <span className={style.error}>Campo obrigatório!</span>
                      )}
                    </label>
                    <input
                      className={style.inputSingle}
                      type="number"
                      id="emailRecoveryPasswordTokenMinutes"
                      placeholder="0"
                      min={0}
                      {...register("emailRecoveryPasswordTokenMinutes", {
                        min: 0,
                        required: smtp,
                      })}
                    />
                  </Stack>
                  <Stack width="100%" gap={0.5}>
                    <label className={style.label} htmlFor="emailDebug">
                      Debug
                    </label>
                    <input
                      style={{
                        width: "20px",
                        height: "20px",
                        marginTop: "5px",
                      }}
                      className={style.check}
                      {...register("emailDebug")}
                      type="checkbox"
                    />
                  </Stack>
                </Stack>
              )}
            </div>
          </div>

          <div className={style.content}>
            <div className={style.contentIcon}>
              <img width={"30px"} src="/assets/IconKeycloak.svg" alt="" />
            </div>
            <div className={style.contentInfo}>
              <div
                onClick={() => setKeyclock(!keycloak)}
                className={style.title}
              >
                <div>
                  Keycloak
                  <input
                    type="checkbox"
                    onChange={(e) => setDisabledKeycloak(e.target.checked)}
                    name=""
                    id=""
                  />
                </div>
                {keycloak ? (
                  <AiOutlineCaretDown
                    onClick={() => setKeyclock(!keycloak)}
                  ></AiOutlineCaretDown>
                ) : (
                  <AiFillCaretRight
                    onClick={() => setKeyclock(!keycloak)}
                  ></AiFillCaretRight>
                )}
              </div>
              {keycloak ? (
                <>
                  <Stack
                    direction={{ xs: "column", md: "row" }}
                    gap={2}
                    width={"93%"}
                    mb={2}
                  >
                    <Stack width={"100%"} gap={0.5}>
                      <label className={style.label} htmlFor="realm">
                        Realm
                      </label>
                      <input
                        disabled={!disabledKeycloak}
                        className={style.inputSingle}
                        type="text"
                        id="realm"
                        placeholder="Realm"
                        {...register("realm")}
                      />
                    </Stack>
                    <Stack width={"100%"} gap={0.5}>
                      <label className={style.label} htmlFor="url">
                        URL
                      </label>
                      <input
                        disabled={!disabledKeycloak}
                        className={style.inputSingle}
                        type="text"
                        id="url"
                        placeholder="URL"
                        {...register("url")}
                      />
                    </Stack>
                  </Stack>
                  <Stack
                    direction={{ xs: "column", md: "row" }}
                    gap={2}
                    width={"93%"}
                    mb={2}
                  >
                    <Stack width={"100%"} gap={0.5}>
                      <label className={style.label} htmlFor="cliendID">
                        Cliend ID
                      </label>
                      <input
                        disabled={!disabledKeycloak}
                        className={style.inputSingle}
                        type="text"
                        id="cliendID"
                        placeholder="Cliend ID"
                        {...register("cliendID")}
                      />
                    </Stack>
                    <Stack width={"100%"} gap={0.5}>
                      <label className={style.label} htmlFor="clientSecret">
                        Client Secret
                      </label>
                      <input
                        disabled={!disabledKeycloak}
                        className={style.inputSingle}
                        type="text"
                        id="clientSecret"
                        placeholder="Client Secret"
                        {...register("clientSecret")}
                      />
                    </Stack>
                  </Stack>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className={style.content}>
            <div className={style.contentIcon}>
              <img width={"30px"} src="/assets/IconDocker.svg" alt="" />
            </div>
            <div className={style.contentInfo}>
              <div onClick={() => setDocker(!docker)} className={style.title}>
                <div>
                  Docker
                  <input
                    type="checkbox"
                    onChange={(e) => setDisabledDocker(e.target.checked)}
                    name=""
                    id=""
                  />
                </div>
                {docker ? (
                  <AiOutlineCaretDown
                    onClick={() => setDocker(!docker)}
                  ></AiOutlineCaretDown>
                ) : (
                  <AiFillCaretRight
                    onClick={() => setDocker(!docker)}
                  ></AiFillCaretRight>
                )}
              </div>
              {docker ? (
                <>
                  <Stack
                    direction={{ xs: "column", md: "row" }}
                    gap={2}
                    width={"93%"}
                    mb={2}
                  >
                    <Stack width={"100%"} gap={0.5}>
                      <label className={style.label} htmlFor="docker">
                        Docker Registry
                      </label>
                      <input
                        disabled={!disabledDocker}
                        className={style.inputSingle}
                        type="text"
                        id="docker"
                        placeholder="Docker Registry"
                        {...register("docker")}
                      />
                    </Stack>
                    <Stack width={"100%"} gap={0.5}>
                      <label className={style.label} htmlFor="dockernamespace">
                        Docker Namespace
                      </label>
                      <input
                        disabled={!disabledDocker}
                        className={style.inputSingle}
                        type="text"
                        id="dockernamespace"
                        placeholder="Docker Namespace"
                        {...register("dockernamespace")}
                      />
                    </Stack>
                  </Stack>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className={style.content}>
            <div className={style.contentIcon}>
              <img width={"30px"} src="/assets/IconKubernetes.svg" alt="" />
            </div>
            <div className={style.contentInfo}>
              <div
                onClick={() => setKubernetes(!kubernetes)}
                className={style.title}
              >
                <div>
                  Kubernetes
                  <input
                    type="checkbox"
                    onChange={(e) => setDisabledKubernetes(e.target.checked)}
                    name=""
                    id=""
                  />
                </div>
                {kubernetes ? (
                  <AiOutlineCaretDown
                    onClick={() => setKubernetes(!kubernetes)}
                  ></AiOutlineCaretDown>
                ) : (
                  <AiFillCaretRight
                    onClick={() => setKubernetes(!kubernetes)}
                  ></AiFillCaretRight>
                )}
              </div>
              {kubernetes ? (
                <>
                  <Stack
                    direction={{ xs: "column", md: "row" }}
                    gap={2}
                    width={"100%"}
                    mb={2}
                  >
                    <Stack width={"250px"} gap={0.5}>
                      <label className={style.label} htmlFor="docker">
                        Use secret to pull image
                      </label>
                      <input
                        className={style.check}
                        type="checkbox"
                        id="docker"
                        placeholder="Docker Registry"
                        disabled={!disabledKubernetes}
                        {...register("kubernetes")}
                      />
                    </Stack>

                    <Stack width={"100%"} gap={0.5}>
                      <label className={style.label} htmlFor="secretpullname">
                        Secret pull name
                      </label>
                      <input
                        disabled={!disabledKubernetes}
                        className={style.inputSingle}
                        type="text"
                        id="secretpullname"
                        placeholder="Secret pull name"
                        {...register("secretpullname")}
                      />
                    </Stack>
                  </Stack>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className={style.contentError}>
            {error && (
              <span>
                {" "}
                <AiFillAlert className={style.iconError}></AiFillAlert> {error}
              </span>
            )}
          </div>
          <div className={style.contentButton}>
            <button disabled={isSubmitting} className={style.buttonSubmit} type={isSubmitting ? "reset" : "submit"}>
              {isSubmitting ? "Gerando..." : "OK! GERAR MICRO-SERVIÇO"}
            </button>
          </div>
        </form>
      </div>
      <PageFooter />
    </div>
  );
};

export default Setup2;
