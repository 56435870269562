import React, { Component, Fragment, useState, useEffect } from "react";
import { Grid, Box } from "@mui/material/";
import { AiFillAlert } from "react-icons/ai";

import CircularProgress from "@mui/material/CircularProgress";
import requestBff from "../utils/RequestBff";
import style from "../css/login.module.css";
import "../App.css";
import { Base64 } from "js-base64";
import { createBrowserHistory } from "history";
import userInfo from "../utils/userInfo";

export const history = createBrowserHistory();

const Login = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [username, setUsername] = useState("");
  const [passwd, setPasswd] = useState("");
  const [authenticated, setAuthenticated] = useState(props.authenticated);
  const [trying, setTrying] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (authenticated) {
      props.history.push("/welcome");
    }
  }, [authenticated, props.history]);

  const handleUsername = (e) => {
    e.preventDefault();
    setUsername(e.target.value);
  };

  const handlePasswd = (e) => {
    e.preventDefault();
    setPasswd(e.target.value);
  };

  const doLogin = async () => {
    const payload = { usuario: username, senha: passwd };
    const urlLogin = props.config.bff + "/user/login";
    setTrying(true);
    setLoading(true);
    try {
      const response = await requestBff(urlLogin, "post", payload);
      setAuthenticated(true);
      sessionStorage.setItem(
        "user-info",
        Base64.encode(JSON.stringify(response.data))
      );
      console.log(response.Cockie);
      setAuthenticated(true);
      userInfo(true);
      setTrying(false);
      setLoading(false);
    } catch (error) {
      console.error(error.message);
      setAuthenticated(false);
      setPasswd("");
      setTrying(false);
      setLoading(false);
      setMessage("Erro de usuário e/ou senha");
      setError(true);
    }
  };

  const displayAlert = message.length > 0 ? "inline-block" : "none";
  const visibilityAlert = message.length > 0 ? "visible" : "none";
  return (
    <div className={style.container}>
      <Grid
        container
        className="login-content"
        sx={{
          verticalAlign: "top",
        }}
      >
        <Grid
          className="login-advert"
          sx={{
            alignContent: "center",
            textAlign: "center",
            marginLeft: "20px",
            display: "inline-block !important",
          }}
        >
          <img
            src="/assets/Logo_AR_Lepton.svg"
            alt="ARLepton"
            width={"480px"}
            height={"380px"}
          />
        </Grid>
        <Grid>
          <Grid
            className="login-form"
            sx={{
              bgcolor: "#fff",
              borderRadius: "8px",
            }}
          >
            <Grid
              container
              item
              sx={{
                alignContent: "center",
                textAlign: "start",
                marginTop: "50px",
                display: "inline-block !important",
              }}
            >
              <label htmlFor="username">Email</label>
              <Box
                sx={{
                  textAlign: "center",
                }}
              >
                <input
                  className={style.inputLogin}
                  id="username"
                  placeholder="Email"
                  onChange={handleUsername}
                  disabled={trying}
                />
              </Box>
            </Grid>
            <Grid
              container
              item
              sx={{
                alignContent: "center",
                padding: "4px",
                display: "inline-block !important",
              }}
            >
              <label htmlFor="passwd">Password</label>
              <Box
                sx={{
                  textAlign: "center",
                }}
              >
                <input
                  className={style.inputLogin}
                  id="passwd"
                  type="password"
                  placeholder="*******"
                  onChange={handlePasswd}
                  disabled={trying}
                />
              </Box>
            </Grid>
            <div className={style.error}>
              {error ? (
                <>
                  <AiFillAlert /> {message}
                </>
              ) : (
                ""
              )}
            </div>
            <Grid
              container
              item
              sx={{
                alignContent: "center",
                textAlign: "center",
                padding: "8px",
                display: "inline-block !important",
              }}
            >
              <button
                className={style.buttonSubmit}
                onClick={doLogin}
                disabled={trying}
              >
                {loading ? (
                  <CircularProgress
                    color="inherit"
                    sx={{ width: "20px", height: "20px" }}
                  />
                ) : (
                  "Acessar"
                )}
              </button>
              <p className={style.forgotPassword}>Esqueceu sua senha?</p>
            </Grid>
          </Grid>
          <Grid
            sx={{
              textAlign: "center",
            }}
          >
            <span className={style.accountValid}>
              Ainda não tem uma conta?
              <span className={style.SignUp}> Sign up</span>
            </span>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
export default Login;
