import React, { useState, useEffect } from "react";
import { Container, Link, Grid } from "@mui/material/";
import { withRouter } from "react-router-dom";
import PageHead from "../components/PageHead";
import PageFooter from "../components/PageFooter";
import userInfo from "../utils/userInfo";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaWhatsapp,
} from "react-icons/fa";

import style from "../css/welcome.module.css";

import "../App.css";
function Welcome(props) {
  const user = userInfo();

  useEffect(() => {
    if (user.authenticated === false) {
      props.history.push("/");
    }
  }, [user.authenticated]);

  return (
    <div className={style.container}>
      <Grid
        container
        spacing={2}
        className={style.welcomeContent}
        sx={{
          verticalAlign: "top",
        }}
      >
        <Grid
          className={style.welcomeAdvert}
          sx={{
            alignContent: "center",
            textAlign: "center",
            marginLeft: "20px",
            display: "inline-block !important",
          }}
        >
          <img
            src="/assets/Logo_AR_Lepton.svg"
            alt="ARLepton"
            width={"400px"}
            height={"300px"}
          />
          <div>
            <Link href={"/setup"}>
              <button className={style.start}>Start</button>
            </Link>
          </div>
          <div className={style.contentInfo}>
            <img
              className={style.logoArPhoneix}
              width={"80px"}
              src="/assets/arp_logo-branco.png"
              alt=""
            />
            <FaFacebookF className={style.icon}></FaFacebookF>
            <FaInstagram className={style.icon}></FaInstagram>
            <FaLinkedinIn className={style.icon}></FaLinkedinIn>
            <FaWhatsapp className={style.icon}></FaWhatsapp>
            <p className={style.direct}>
              @AR Lepton é um produto da AR Phoenix. Todos os direitos
              reservados
            </p>
          </div>
        </Grid>
        <Grid item mt={2} ml={4}>
          <img
            src="/assets/ilustra.png"
            alt="ARLepton"
            width={"780px"}
            height={"580px"}
          />
        </Grid>
      </Grid>
      {/* */}
    </div>
  );
}

export default withRouter(Welcome);
