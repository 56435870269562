import { Stack } from "@mui/material/";
import axios from "axios";
import fileDownload from "js-file-download";
import { useEffect, useState } from "react";
import { AiOutlineDownload } from "react-icons/ai";
import Menu from "../components/Menu";
import style from "../css/download.module.css";
import requestBff from "../utils/RequestBff";
import userInfo from "../utils/userInfo";
import zipReact from "../template/React.zip";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const Download = (props) => {
  const [token, setToken] = useState();
  const [working, setWorking] = useState(false);
  const infoUser = userInfo();
  const [react, setReact] = useState(false);
  const { architectureModel } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (infoUser.authenticated === false) {
      props.history.push("/");
    }
  }, [infoUser.authenticated]);

  const downloadInfo =
    sessionStorage.getItem("ms") && JSON.parse(sessionStorage.getItem("ms"));

  const downloadMicroservico = async () => {
    if (!downloadInfo) return;
    setIsLoading(true);
    var urlToken = props.config.bff + "/user/token";
    var downloadEndpoint =
      props.config.generator + "/java/download/" + downloadInfo.appZipFile;
    requestBff(urlToken, "get", null)
      .then((response) => {
        setToken({ token: response.data });
        console.log("Enviando dados para download do MS");
        setWorking({ working: true });
        var headers = {
          "Content-Type": "application/json",
          Authorization: response.data,
          responseType: "blob",
        };
        axios
          .get(downloadEndpoint, headers)
          .then((res) => {
            fileDownload(res.data, downloadInfo.appZipFile);
            sessionStorage.setItem("ms", null);
            props.history.push("/welcome");
          })
          .then(() => {
            if (react) return downloadReact();
          })
          .finally(() => {
            setIsLoading(false);
          });
      })
      .catch((error) => {
        console.error(JSON.stringify(error.message));
        setWorking({ error: [], working: false });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const downloadReact = async () => {
    const link = document.createElement("a");
    link.href = zipReact;
    link.download = "template-react.zip";
    link.click();
  };

  return (
    <>
      <Menu />
      <div className={style.container}>
        <div className={style.content}>
          <div className={style.contentIcon}>
            <AiOutlineDownload className={style.icon}></AiOutlineDownload>
          </div>
          <div className={style.contentInfo}>
            <div className={style.title}>Informações do MicroServiço</div>
            <Stack
              direction={{ xs: "column", md: "row" }}
              gap={2}
              alignItems="end"
              mb={2}
              className={style.contentForm}
            >
              <Stack width="100%" gap={0.5}>
                <label className={style.label} htmlFor="apptype">
                  App Type
                </label>
                <input
                  disabled
                  className={style.input}
                  type="text"
                  id="apptype"
                  defaultValue={downloadInfo?.appType}
                  placeholder="App Type"
                />
              </Stack>
              <Stack width="100%" gap={0.5}>
                <label className={style.label} htmlFor="appname">
                  App Name
                </label>
                <input
                  disabled
                  className={style.input}
                  type="text"
                  id="appname"
                  placeholder="App Name"
                  defaultValue={downloadInfo?.appName}
                />
              </Stack>
              <Stack width="100%" gap={0.5}>
                <label className={style.label} htmlFor="Version">
                  Version
                </label>
                <input
                  disabled
                  className={style.input}
                  type="text"
                  id="Version"
                  placeholder="Version"
                  defaultValue={downloadInfo?.appVersion}
                />
              </Stack>
            </Stack>
            {architectureModel === "internal" && (
              <Stack width="100%" gap={0.5} flexDirection="row" mb={3}>
                <label className={style.label} htmlFor="React">
                  Deseja baixar o template React?
                </label>
                <input
                  checked={react}
                  onChange={(e) => setReact(e.target.checked)}
                  type="checkbox"
                  className={style.input}
                  id="React"
                  placeholder="React"
                />
              </Stack>
            )}
          </div>
        </div>
        <div className={style.contentButton}>
          <button
            disabled={!downloadInfo || isLoading}
            onClick={downloadMicroservico}
            className={style.buttonSubmit}
          >
            {isLoading ? "Aguarde..." : "Download"}
          </button>
        </div>
      </div>
    </>
  );
};

export default Download;
